import { React, useState, useEffect } from "react";
import { api } from "../utils/api"; // Import your api function
import { ErrorMessage } from "formik";

export const DATA_GRID_STYLES = "box-content shadow-sm grid grid-cols-2 divide-y border [&>span]:p-4";

const BasicUserInfo = ({ user, hideResendWelcomeEmail }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const ErrorMessage = () =>
    error && (
      <div className="fixed bottom-0 right-0">
        <div
          id="basicUserInfo-alert-error"
          className="flex items-center p-2 mb-2 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
          role="alert"
        >
          <div className="ms-3 text-sm font-medium">{error}</div>
        </div>
      </div>
    );
  const SuccessMessage = () =>
    success && (
      <div className="fixed bottom-0 right-0">
        <div
          id="basicUserInfo-alert-success"
          className="flex items-center p-2 mb-2 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
          role="alert"
        >
          <div className="ms-3 text-sm font-medium">{success}</div>
        </div>
      </div>
    );

  const resendWelcomeEmail = async () => {
    // Wrap in a function declaration
    const url = window.location.href;
    let userType = null; // Declare userType variable

    if (url.includes("customer")) {
      userType = "customer";
    } else if (url.includes("partner") && url.includes("user")) {
      userType = "partner";
    } else if (url.includes("merchant") && url.includes("user")) {
      userType = "merchant";
    } else if (url.includes("administrator")) {
      userType = "administrator";
    }

    let endpoint = null;
    const dataToSend = {
      idUser: user._id
    };
    switch (userType) {
      case "customer":
        endpoint = `v1/customer/resendInvitation`;
        break;

      case "partner":
        endpoint = `v1/user/sendWelcomeEmail`; // Corrected endpoint
        break;

      case "merchant":
        endpoint = `v1/merchantUser/sendWelcomeEmail`; // Corrected endpoint
        break; 
        
      case "administrator":
        endpoint = `v1/administrator/sendWelcomeEmail`; // Corrected endpoint
        break;

      default:
        break;
    }

    try {
      if (userType && endpoint) {
        const response = await api(endpoint, "POST", dataToSend); // Assuming no data to send
        if (response.data.success) {
          setSuccess(response.data.message);
        } else {
          setError(response.data.message);
          alert(response.data.message);
        }
      } else {
        setError("User type or Endpoint can not be resolved.");
      }
    } catch (error) {
      setError("API error:", error);
    }
  };

  return (
    <div className={DATA_GRID_STYLES}>
      <span className="font-semibold border-none">Email</span>
      <span className="font-semibold border-none">
        <a href={"mailto:" + user.email}>{user.email}</a>
        {!hideResendWelcomeEmail && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
            onClick={() => resendWelcomeEmail(user)}
          >
            Resend Welcome Email
          </button>
        )}
      </span>
      <span>Full Name</span>
      <span>
        {user.firstName} {user.lastName}
      </span>
      <span className="font-semibold">Phone</span>
      <span className="font-semibold">
        {user.phone ? <a href={"tel:" + user.phone}>{user.phone}</a> : "No phone available"}
      </span>
      <span>STREET</span>
      <span>{user?.address?.street || "-"}</span>
      <span>CITY</span>
      <span>{user?.address?.city || ""}</span>
      <span>STATE</span>
      <span>{user?.address?.state || ""}</span>
      <span>POSTCODE</span>
      <span>{user?.address?.postcode || ""}</span>
      <span>COUNTRY</span>
      <span>{user?.address?.country?.value || user?.address?.country || ""}</span>
      {user?.vehicles?.length > 0 && (
        <>
          <span className="font-semibold">Vehicles</span>
          <span className="flex flex-column box-content">
            {user.vehicles.map((vehicle) => (
              <a href={"/vehicle/" + vehicle?._id}>{vehicle?.rego}</a>
            ))}
          </span>
        </>
      )}
      {user?.role && (
        <>
          <span>Role</span>
          <span>{user.role}</span>
        </>
      )}
      <span className="font-semibold">Status</span>
      <span className="flex flex-column box-content">{user.active ? "Active" : "Not Active"}</span>
      <div className="flex absolute">
        <SuccessMessage />
        <ErrorMessage />
      </div>
    </div>
  );
};

export default BasicUserInfo;
